$ln-webpack: true;

@import "@jsluna/style/scss/main.scss";

/* App Specific Styles Go Here */

[id^="navLink-"].active {
  @extend .is-active;
}
//Accordian Css

.s-accordian {
  background: none !important;
  text-decoration: underline;
  color: #404040;
  padding: 0 !important;
}

.s-overflow {
  max-height: 200px;
  overflow-y :hidden;
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent)
}
#site-title {
  text-decoration: none;
}

.DateInput {
  width: 100%;
}

.s-info-panel {
  background-color: aliceblue;
  border: 1px;
  border-style: solid;
  border-color: cornflowerblue;
}

.s-person-details {
  background-color: rgb(225, 228, 230);
  border: 1px;
  border-style: solid;
  border-color: rgb(43, 44, 44);
}

.s-page-push {
  margin-top: 1rem;
}

.s-page-alert {
  animation-name: slideDown;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  margin-bottom: 1rem;
  padding: 20px;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.s-no-link-style:active,
.s-no-link-style:visited {
  color: inherit;
  text-decoration: inherit;
}

.ln-o-container--xs {
  max-width: 35rem;
}

@media only screen and (max-width: 600px) {
  .ln-o-container--xs.s-pageName {
    margin-top: 3rem !important;
  }

  button#s-back-button {
    margin-top: 2.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .s-page-title {
    padding: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .s-page-title {
    padding: 1rem;
  }
}

@media only screen and (max-width: 720px) {
  .s-page-push {
    margin-top: 5rem;
  }
}

.ln-o-page__header + .ln-o-page__body {
  margin-top: 0px;
}

.s-page-label-display {
  display: inline-block;
}

//Custom
@import './components/common';
@import './components/fileUpload.scss';
@import './components/utils';
@import './components/imageUpload';
@import './components/radioButton';
@import './components/colleagueStoreSelector';
@import './components/semanticBox';
@import './components/hyperLink';
@import './components/accident';
@import './components/text';
@import './components/list';
@import './components/lookup';
@import './components/card'