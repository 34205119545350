.s-manual-address {
    background-color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
    font-family: "MaryAnn";
    font-size: 1.125rem;
}

.s-error-address {
    background-color: #ffffff;
    font-weight: bold;
    font-family: "MaryAnn";
    font-size: 1.125rem;
}