/// Hides elements at given breakpoints.
/// @name hidden
/// @group utilities/display
/// @example markup
///   <div class="s-u-hidden">I'm hidden</div>
///   <div class="s-u-hidden@max-sm">I'm hidden until sm</div>
///   <div class="s-u-hidden@sm">I'm hidden up to sm</div>
///

.s-u-hidden {
  display: none !important;
}

@each $key, $value in $ln-breakpoints {
  .s-u-hidden\@max-#{$key} {
    @include ln-respond-to('#{$key}') {
      display: none !important;
    }
  }

  .s-u-hidden\@#{$key} {
    @include ln-respond-to('max-#{$key}') {
      display: none !important;
    }
  }
}
