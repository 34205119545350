.s-information-box {
    background-color: #e8f1fb;
    border-style: solid;
    border-width: thin;
    border-color: #428fda;
}

.s-warning-box {
    background-color: #fffbe6;
    border-style: solid;
    border-color: #ffdd2e;
}

.s-error-box {
    background-color: #fbe6e6;
    border-style: solid;
    border-color: #dd2e2e;
}

.s-success-box {
    background-color: #e6f4ed;
    border-style: solid;
    border-color: #40a673;
}

.s-summary-box {
    background-color: rgb(225, 228, 230);
    border-style: solid;
    border-width: thin;
    border-color: rgb(43, 44, 44);
}

.s-summary-box-investigation {
    background-color: rgb(225, 228, 230);
    border-style: solid;
    border-width: thin;
    border-color: rgb(43, 44, 44) !important;
}

.s-semantic-box {
    padding: 20px;
    padding-bottom: 0;
    margin-bottom: 20px;
}

@media (min-width: 720px) {
    .s-semantic-box {
        padding: 20px;
    }
}