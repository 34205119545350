.report-accident-button {
  width: 550px;
}

.no-background {
  background: inherit !important;
}

.ln-c-card--soft {
  border: 1px solid #dfd5d5;
}

@media only screen and (max-width: 600px) {
  .report-accident-button-mobile {
    display:block;
    width:90%;
    margin:auto;
    margin-top: 50px;
  }
}