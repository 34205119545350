.s-no-link-style,
.s-no-link-style:hover,
.s-no-link-style:active,
.s-no-link-style:visited {
  color: inherit;
  text-decoration: inherit;
}


@media only screen and (max-width: 720px) {
  button#s-back-button {
    margin-top: -16px;
  }
}

@media only screen and (min-width: 720px) {
  button#s-back-button {
    margin-top: -52px;
  }
}

@media only screen and (min-width: 720px) {
  div.ln-o-page__body {
    margin: 0px 0px 0px !important;
  }
}
