.s-card-clickable {
    cursor: pointer;
}

.s-card-faded {
    opacity: 0.7
}

.s-card-faded:hover {
    opacity: 1
}